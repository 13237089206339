
import { Component, Vue } from 'vue-property-decorator';
import { AdminService } from '@/services';
import dayjs from 'dayjs';
import { FeatureModule } from '@/store/modules';

@Component({
  components: {}
})
export default class AdminBrands extends Vue {
  menuStartDate = false;
  menuEndDate = false;
  chargeFile = [{}];
  showTable = false;
  showRoundUp = false;
  startDate = dayjs().utc().format('YYYY-MM-DD');
  endDate = dayjs().utc().format('YYYY-MM-DD');

  headers = [
    { text: 'START DATE', value: 'startDate' },
    { text: 'END DATE', value: 'endDate' },
    { text: 'ACTION', value: 'actions', sortable: false }
  ];

  async generateFile(): Promise<void> {
    if (dayjs(this.startDate).isAfter(dayjs(this.endDate))) {
      this.$notifyError('Start date cannot be greater than end date');
      return;
    }
    const { isRoundUpEnabled } = FeatureModule.getFeatureStatuses;
    this.showRoundUp = isRoundUpEnabled;

    this.showTable = true;
    this.chargeFile = [{ startDate: this.startDate, endDate: this.endDate }];
  }

  async getAdminCharges(): Promise<void> {
    try {
      const payload = {
        startDate: dayjs(this.startDate).startOf('day').toDate(),
        endDate: dayjs(this.endDate).endOf('day').toDate()
      };
      await AdminService.fetchCharges(payload);
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async getAdminOfferRedemptions(): Promise<void> {
    try {
      const payload = {
        startDate: dayjs(this.startDate).startOf('day').toDate(),
        endDate: dayjs(this.endDate).endOf('day').toDate()
      };
      await AdminService.fetchOfferRedemptionsTransactions(payload);
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }
}
